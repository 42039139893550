@import "../../variables";

.gutter {
  max-width: 750px;
  min-width: 320px;
  margin: auto;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  @include fridge-large {
    max-width: 100%;
  };
}
