.container {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.inner {
  width: 200%;
  height: 100%;
  display: flex;
  flex-direction: row;
  transition: margin-left 0.5s;
}

.inner.opened {
  margin-left: -100%;
}

.left,
.right {
  width: 50%;
  height: 100%;
  position: relative;
  overflow: auto;
}

.close,
.close:visited,
.close:hover,
.close:active {
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;
  background: none;
  outline: none;
  padding: 0;
  margin: 0;
  border: none;
  width: 1.5rem;
  height: 1.5rem;
}

.close svg {
  fill: #ffffff;
  width: 100%;
  height: 100%;
}
