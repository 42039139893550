@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700&display=swap");
@import url("https://pgjs.paygreen.fr/latest/paygreen.min.css");
@import "variables";


*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  height: 100%;
  min-height: 520px;
}

body {
  height: 100%;
  font-family: Montserrat, serif;
  color: $black;
}

body #krtoolbar {
  bottom: -36px;
}

#root {
  position: relative;
  text-align: center;
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

button {
  cursor: pointer;
  padding: 0;
}
