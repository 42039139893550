@import "../../variables";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.list-container {
  flex: 1;
  display: flex;
  width: 100%;
  margin: 1.5rem 0;
}

.list-container > * {
  width: 100%;
}

.head {
  display: flex;
  width: 100%;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
}

.messages {
  padding: 0 1.5rem;
}

.label {
  text-align: center;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 600px;
  margin: 1.2rem auto;

  span {
    font-weight: bold;
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
    color: #989898;

    &:first-of-type {
      font-weight: bold;
      font-size: 1.125rem;
      color: #17BEBB;

      @include fridge-medium {
        font-size: 1.75rem;
        margin-bottom: 2rem;
      }

      @include fridge-large {
        font-size: 40px;
      }
    }

    &:last-of-type {
      font-size: 0.875rem;
      color: #989898;
      font-weight: normal;

      @include fridge-large {
        font-size: 20px;
      }
    }

    @include fridge-large {
      font-size: 20px;
    }
  }
}

.head .label > span {
  margin-bottom: 1rem;
}

.icons {
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    height: 211px;
    width: auto;
    margin: 40px 40px;
    filter: grayscale(1);

    @include fridge-large {
      height: 370px;
      margin: 40px 40px;
    }
  }
}

svg.stepActive {
    filter: none;
}

.icon {
  width: 131px;
  height: 211px;
  min-width: 131px;
  min-height: 211px;
}

.icon > svg {
  max-width: 100%;
  max-height: 100%;
}

.menuTitle {
  color: #0c0f0a;
  font-family: Montserrat, sans-serif;
  font-size: 40px;
  font-weight: 600;
}

.text {
  width: 100%;
  text-align: center;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  color: #989898;
}
