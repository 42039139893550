@import "../../variables";

.title {
  display: none;

  @include fridge-medium {
    display: inline-block;
    text-align: center;
    padding: 2rem;
    width: 100%;
    color: #0c0f0a;
    font-size: 40px;
    font-weight: 600;
  }
}

.products {
  display: table;
  width: 100%;
  text-align: left;
  overflow: auto;
  font-family: Montserrat, sans-serif;
}

.product {
  font-family: Montserrat, sans-serif;
  font-size: 0.8125rem;
  line-height: 1.75rem;
  display: flex;
  align-items: center;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  background: none;

  @include fridge-medium {
    font-size: 1.25rem;
    line-height: 2.5rem;
  }

  @include fridge-large {
    font-size: 1.25rem;
    line-height: 2.5rem;
  }
}

.body {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  margin-right: 20px;
}

.productName {
  flex: 1;

  @include fridge-large {
    font-weight: 600;
    line-height: 26px;
    font-size: 26px;
    margin-bottom: 8px;
  }
}

.quantityContainer {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}

.quantity {
  font-weight: 300;
  font-size: 32px;
  color: #989898;
}

.icon {
  margin-right: 1.25rem;
  width: 45px;
  height: 35px;

  @include fridge-medium {
    width: 90px;
    height: 70px;
  }

  @include fridge-large {
    margin-right: 20px;
    height: 100px;
    width: 100px;
  }
}

.icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.productPrice {
  text-align: right;
  color: #909090;

  @include fridge-large {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    text-align: left;
    font-size: 20px;
    line-height: 26px;
    font-weight: 400;
    color: $primary-color;
  }
}

.productPriceInitial {
  text-decoration: line-through;
  font-size: 0.7rem;
  line-height: 1rem;
  color: #989898;
  margin-left: 1em;
}

.infoIcon {
  position: absolute;
  top: 14px;
  right: 14px;
}

.promotion {
  border: 1px solid #F05373;
  font-weight: 600;
  font-size: 14px;
  padding: 1px 6px;
  line-height: 1em;
  color: white;
  background-color: #F05373;
  border-radius: 35px;
}

.seePromotion {
  position: absolute;
  display: inline-block;
  border: 1px solid #F05373;
  text-decoration: underline;
  font-weight: 600;
  font-size: 16px;
  padding: 5px 10px;
  line-height: 1em;
  color: white;
  background-color: #F05373;
  border-radius: 4px;
  bottom: 14px;
  right: 14px;
}
