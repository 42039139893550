.container {
  padding: 1rem 1.875rem;
}

.fridge-status {
  box-shadow: 0px 2px 28px rgba(0, 0, 0, 0.06);
  border-radius: 9px;
  background: #ffffff;
  padding: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.info {
  display: flex;
  flex-direction: column;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  color: #0c0f0a;
  margin-right: 0.5rem;
}

.status {
  padding: 0.5rem;
  border-radius: 4px;
  display: flex;
  align-items: center;
  width: 120px;
  justify-content: center;
}

.text {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 0.75rem;
  color: #ffffff;
  margin-right: 6px;
}

.icon {
  fill: #ffffff;
  width: 20px;
  height: auto;
}

.closed {
  background-color: #f05373;
}
.open {
  background-color: #7ed321;
}
