@import '../../variables';

.navbar {
  width: 100%;
  padding: 1rem 0;
  color: white;
  font-family: Montserrat, sans-serif;
  display: flex;
  justify-content: center;
  position: relative;
  background: linear-gradient(90.57deg, #17bebb 0%, #2a2a72 100%) no-repeat
    center center fixed;

  @include fridge-large {
    padding: 30px 0;
  }
}

.logo {
  height: 3.5rem;
  fill: #ffffff;

  @include fridge-large {
    height: 120px;
    width: auto;
  }
}

.backButton {
  position: absolute;
  left: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
}

.menuButton {
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
}
