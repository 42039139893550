.form {
  height: 100%;
  width: 100%;
}

.terms-label {
  color: #0c0f0a;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  text-align: center;
  margin: 1rem 2rem;
}

.terms-label a {
  text-decoration: none;
  font-weight: bold;
  color: #2a2a72;
}
