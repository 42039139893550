.button,
.button:visited,
.button:hover,
.button:active {
  width: 100%;
  border: none;
  background: none;
  outline: none;
  padding: 0;
  margin: 0;
  text-align: left;
  color: #0c0f0a;
  position: relative;
  display: flex;
  align-items: center;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
}

.icon {
  margin-right: 0.75rem;
  width: 30px;
  height: 30px;
  position: relative;
}

.icon > svg {
  width: 100%;
  fill: #2a2a72;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}

.icon.fridge > svg {
  stroke: #2a2a72;
}

.text {
  padding-right: 1.8125rem;
}

.chevron {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  fill: #2a2a72;
}
