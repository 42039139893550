@import "../../../variables";

.button,
.button:visited,
.button:hover,
.button:active {
  position: relative;
  background: transparent;
  outline: 0;
  width: 100%;
  height: 50px;
  margin-top: 1rem;
  line-height: 50px;
  /* Button */
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 0.8125rem;
  text-align: center;
  /* Default */
  color: #0c0f0a;
  border: 1px solid #989898;
  border-radius: 9px;
  padding: 0 1rem;

  @include fridge-large {
      margin-top: 0;
      margin-right: 1rem;
      :last-child {
          margin-right: 0;
      }
  }
}

.button[disabled] {
  opacity: 0.5;
}

.button:first-child {
  margin-top: 0;
}

.button.button_primary {
  background: #2a2a72;
  border-radius: 9px;
  color: #ffffff;
  border: none;
}

.button.button_secondary {
  background: #ffffff;
  border: none;
  color: #03b3de;
}

.button.button_minimalist {
  border: none;
  color: #989898;
}

.button.button_secondary .chevron {
  fill: #03b3de;
}

.button.button_error {
  background: linear-gradient(102.69deg, #8d1a01 1.35%, #b82727 103.4%);
  border: none;
}

.chevron {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  fill: currentColor;
  width: 1.5rem;
  height: 1.5rem;
}

.loading {
  right: 5px;
  width: 2.125rem;
  height: 2.125rem;
  fill: #ffffff;
}

.back-button,
.back-button:visited,
.back-button:hover,
.back-button:active {
  position: relative;
  background: transparent;
  outline: 0;
  font-family: Montserrat, sans-serif;
  font-weight: bold;
  font-size: 0.875rem;
  color: #ffffff;
  border: none;
  height: 1.5rem;
  padding: 0 0 0 1.5rem;
  box-sizing: border-box;
}

.back-button .chevron {
  left: 0;
  right: auto;
  transform: translateY(-50%) rotate(180deg);
  width: 3.5rem;
  height: 3.5rem;
}

.menu-button,
.menu-button:visited,
.menu-button:hover,
.menu-button:active {
  position: relative;
  background: transparent;
  outline: 0;
  border: none;
  padding: 0;
  margin: 0;
}

.menu-button svg {
  fill: currentColor;
  height: 2rem;
  width: 2rem;
}
