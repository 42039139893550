@import "../../variables";

.paymentMethodPopup {
  width: auto;
  padding: 1rem 3rem;
  text-align: center;
}

.authorizationDisclaimer {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 1rem;
  position: relative;

  img {
    width: 2rem;
    position: absolute;
    top: -1rem;
    left: -1rem;
  }
}

.paymentMethodCards {
  margin-bottom: 3rem;
  p {
    margin-bottom: 1rem;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    display: flex;
    flex-wrap: wrap;

    li {
      width: calc(25% - 2rem);
      margin: 5px 1rem;
    }
  }

  svg {
    width: 50%;
    height: 100%;
  }
}

.paymentMethodApp {
  p {
    margin-bottom: 1rem;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    display: flex;
    flex-wrap: wrap;

    li {
      width: calc(33% - 2rem);
      margin: 5px 1rem;
    }
  }

  svg {
    width: 50%;
    height: 100%;
  }
}

.qrcodeAlt {
  display: flex;
  flex-direction: column;
  font-size: 20px;

  span {
    word-break: break-all;

    &:nth-of-type(2) {
      font-weight: 700;
      color: #2A2A72;
    }
    &:nth-of-type(3) {
      font-weight: 700;
    }
  }
}
