.main {
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #F4F4F7;
}

.view {
  flex: 1;
  position: relative;
}

.view > div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
