.button,
.button:visited,
.button:hover,
.button:active {
  width: 100%;
  border: none;
  background: none;
  outline: none;
  padding: 0;
  margin: 0;
  text-align: left;
  color: #0c0f0a;
  position: relative;
  line-height: 1.8rem;
}

.radio {
  height: 120%; /* radio svg has not same box as flag svg so to get same size, getted by line height, we need to set more height */
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  fill: #989898;
}
