.tutorial {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: Montserrat, sans-serif;
}

.step-scroll {
    width: 100%;
    flex: 1;
    height: 0;
    position: relative;
}

.step-scroll-inner {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
}

.steps {
    width: 300%;
    height: 100%;
    display: flex;
    flex-direction: row;
}

.step {
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.visual-container {
    flex: 1 1 0;
    height: 0;
    position: relative;
}

.visual {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 1rem;
    border: none;
    margin: 0;
    outline: none;
    background: none;

    > img {
        object-fit: contain;
        width: 100%;
        height: 100%;
    }
}

.text {
    padding: 0 1rem;
}

.title {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    color: #17BEBB;
}

.description {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #0C0F0A;
}

.dots {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1rem;
}

.dot {
    display: inline-block;
    width: 8px;
    height: 8px;
    background: #C4C4C4;
    border-radius: 4px;
    position: relative;

    + .dot {
        margin-left: 0.75rem;
    }
}

.dot-active:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 14px;
    height: 14px;
    margin-top: -3px;
    margin-left: -3px;
    border-radius: 7px;
    background: rgba(23, 190, 187, 0.24);
}

.dot-active:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background: #17BEBB;
}

.buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
}

.button-large {
    flex: 1;
}

.next-button {
    display: flex;
    align-items: center;
}

.chevron {
    margin-right: -8px;
    fill: #03b3de;
}
