.content {
  font-size: 0.875rem;
}

.buttons {
  margin-top: 1rem;
  display: flex;
}

.buttons button,
.buttons button:visited,
.buttons button:hover,
.buttons button:active {
  margin-top: 0;
}

.buttons button:first-child {
  margin-right: 1rem;
}
