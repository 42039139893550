@import "../../variables";

.wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.90);
  z-index: 2;
  overflow: auto;
}

.popupCloseIcon {
  position: absolute;
  right: 7px;
  top: 7px;
  fill: #fff;
  width: 36px;
  height: 36px;
}

.notificationBody {
  position: relative;
}

.popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: transparent;
  font-family: Montserrat, serif;
  color: #fff;
  max-height: 100vh;
  width: 100%;
}

.popupHeader {
  margin-bottom: 2rem;
  text-align: center;

  h3 {
    font-weight: 700;
    @include fridge-medium {
      font-size: 40px;
    }
  }

  @include fridge-large {
    margin-bottom: 5rem;
  }
}

.popupSubtitle {
  font-weight: 700;
}

.popupBody {
  width: 100%;

  background-color: #fff;
  padding: 2rem;
  border-radius: 9px;
  color: #0C0F0A;
  font-size: 18px;
  margin-left: auto;
  margin-right: auto;
  max-width: 90%;

  svg {
    fill: #2A2A72;
  }

  p {
    margin-bottom: 1rem;
  }

  @include fridge-medium {
    width: 500px;
    font-size: 20px;
  }

  @include fridge-large {
    width: 745px;
    padding: 5rem;

    p {
      margin-bottom: 5rem;
    }
  };
}

.popupFooter {
  display: none;
  margin: 2rem;

  @include fridge-medium {
    display: block;
  }

  @include fridge-large {
    margin: 5rem;
  }
}

.popupCloseBtn,
.popupCloseBtn:hover,
.popupCloseBtn:visited,
.popupCloseBtn:active,
{
  margin-top: 0;
  border-color: #fff;
  width: 300px;
  height: 60px;
  color: #fff;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;

  svg {
    fill: #fff;
    width: 36px;
    height: 36px;
    margin-right: 10px;
  }

  @include fridge-large {
    width: 426px;
    height: 76px;
  }
}

.close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 25px;
  height: 25px;
}
