@import "../../variables";

.notificationPopup {
  text-align: center;
}

@keyframes bumping-img {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

.notificationPicture {
  height: 200px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;

  img {
    height: 100%;
    animation-name: bumping-img;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease;
  }

  @include fridge-large {
    margin-bottom: 2rem;
  }
}

.notificationStatus {
  font-weight: bold;
  font-size: 1.2rem;
  margin-bottom: 1rem;
  @include fridge-large {
    margin-bottom: 2rem;
  }
}

.notificationDescription {
  line-height: 1.4rem;
}
