$breakpoints: (
    fridge_medium: 768px,
    fridge_large: 960px
);

@mixin fridge-medium {
  @media screen and (min-width: map-get($breakpoints, fridge_medium)){
    @content
  }
}

@mixin fridge-large {
    @media screen and (min-width: map-get($breakpoints, fridge_large)){
        @content
    }
}

$black: #0C0F0A;
$grey: #989898;
$light: #C4C4C4;
$white: #FFFFFF;

$primary-color: #2A2A72;
$secondary-color: #17BEBB;
$alert-color: #F05373;
$available-color: #7ED321;
$not-available-color: #FFD453;
