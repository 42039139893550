.button {
  width: 100%;
  border: none;
  background: none;
  outline: none;
  padding: 0;
  margin: 0;
  text-align: left;
  color: #0c0f0a;
  position: relative;
  display: inline-block;
  text-decoration: none;
}

.sub-info {
  font-size: 0.75rem;
  line-height: 1rem;
  margin-top: 5px;
}

.icon,
.icon:visited,
.icon:hover,
.icon:active {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  fill: #2a2a72;
  background: none;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  width: 0.875rem;
  display: block;
}

.icon > svg {
  width: 100%;
  height: auto;
  display: block;
}

.warning {
  height: 1rem;
  width: auto;
  fill: #0c0f0a;
  vertical-align: middle;
  margin-right: 5px;
}

.info {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.375rem;
}

.info > svg {
  margin-right: 5px;
  height: 1rem;
  width: auto;
  vertical-align: middle;
}

.delete path {
  fill: #2a2a72;
}
