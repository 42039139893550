@import "../../variables";

.container {
  padding: 2rem 1.875rem;
  width: 100%;
}

.fridge {
  margin-bottom: 2rem;
  color: #0c0f0a;
  font-family: Montserrat, sans-serif;
  font-size: 2rem;
  font-weight: 600;
}

.menuTitle {
  color: #0c0f0a;
  font-family: Montserrat, sans-serif;
  font-size: 40px;
  font-weight: 600;
}

.buttons {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include fridge-large {
      flex-direction: row;
      justify-content: center;
  }
}

.buttons button,
.buttons button:visited,
.buttons button:hover,
.buttons button:active {
  width: 260px;
}

.paymentMethodsTitle {
  font-weight: 700;
  font-size: 20px;
  margin: 1rem 0;
}

.paymentMethods {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 1.875rem;
}

.paymentMethodsBtn {
  flex: 1;
}

.paymentMethodsOr {
  display: inline-block;
  text-align: center;
  font-weight: 700;
  font-size: 1rem;
  margin: 0 20px;
  background-color: $light;
  border-radius: 50%;
  padding: 10px;
  color: white;

  @include fridge-large {
    font-size: 20px;
    margin: 5px 20px;
    padding: 17px;
  }
}

.paymentMethodsSeparator {
  margin: 20px;

  hr {
    width: 2px;
    height: 20px;
    border-style: solid;
    border-width: 0;
    background-color: $light;
  }
}

.qrcode {
  display: flex;
  justify-content: center;
  padding: 1rem;
}
