.paper-aligner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 1rem 1.875rem;
  flex-direction: column;
}

.paper {
  padding: 1rem;
  width: 100%;
  box-sizing: border-box;
  border-radius: 9px;
  text-align: center;
  font-family: Montserrat, sans-serif;
  max-height: 100%;
}

.paper.white {
  box-shadow: 0 2px 28px rgba(0, 0, 0, 0.06);
  background: #ffffff;
}

.paper.transparent {
  background: transparent;
  padding: 0;
}

.paper-large-title {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1.125rem;
  color: #0c0f0a;
  padding-bottom: 1.625rem;
}

.paper-title {
  font-family: Montserrat, sans-serif;
  text-align: center;
  line-height: 26px;
  font-size: 1.125rem;
  margin-bottom: 1rem;
}

.paper-large-footer {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-size: 1.125rem;
  color: #0c0f0a;
  padding-top: 1.625rem;
}

.paper-footer {
  font-family: Montserrat, sans-serif;
  text-align: center;
  line-height: 26px;
  font-size: 1.125rem;
  margin-top: 1rem;
}
