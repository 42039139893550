@import "../../variables";

.container {

}

.steps {
  display: flex;
  align-items: center;
  justify-content: center;

  svg, img {
    height: 211px;
    width: auto;
    margin: 40px 40px;
    filter: grayscale(1);

    @include fridge-large {
      height: 370px;
      margin: 40px 40px;
    }
  }
}

svg.active, img.active {
  filter: none;
}

.body {

}
