.container {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  svg {
    width: 106px;
    height: auto;
  }
}
