.order-bill-tip {
  font-family: Montserrat, sans-serif;
  text-align: center;
  line-height: 26px;
  font-size: 1.125rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.order-bill-tip img {
  height: 3.25rem;
  width: 3.25rem;
}
