#add-card-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2em;
}

#add-card-form > div,
#add-card-form > button {
  max-width: 290px;
  width: 100%;
  margin-left: 0;
}

#add-card-form > button {
  position: relative;
}

#add-card-form > button .kr-spinner,
#add-card-form > button .kr-icon {
  width: 100%;
}
