.logo-container {
  width: 100%;
  height: 100%;
  position: relative;
  background: linear-gradient(90.57deg, #17bebb 0%, #2a2a72 100%) no-repeat
    center center fixed;
}

.logo {
  display: block;
  width: 50%;
  height: 50%;
  fill: #ffffff;
  top: 25%;
  left: 25%;
  position: absolute;
}
