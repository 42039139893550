@import "../../variables";

.container {
  font-family: Montserrat, sans-serif;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;
  justify-content: center;
}

.loader-container {
  height: 0;
  flex: 1;
  position: relative;
}

.loader-content {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.loader {
  flex: 1;
  height: 0;
  position: relative;
}

.loader > svg {
  fill: #2a2a72;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 25%;
  height: 25%;
  transform: translate(-50%, -50%);
}

.loader > img {
  padding: 9% 0;
  position: absolute;
  top: 0;
  left: 50%;
  height: 100%;
  transform: translateX(-50%);
}

.label {
  text-align: center;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 600px;
  margin: 1.2rem auto;

  span {
    font-size: 0.875rem;
    margin-bottom: 0.5rem;

    &:first-of-type {
      font-weight: bold;
      font-size: 1.125rem;
      color: #17BEBB;

      @include fridge-medium {
        font-size: 1.75rem;
        margin-bottom: 2rem;
      }

      @include fridge-large {
        font-size: 40px;
      }
    }

    &:last-of-type {
      font-size: 0.875rem;
      color: #989898;

      @include fridge-large {
        font-size: 20px;
      }
    }

    @include fridge-large {
      font-size: 20px;
    }
  }

  @include fridge-medium {
    margin: 1.2rem auto 4rem;
  }
}

.text {
  font-weight: bold;
  font-size: 1.125rem;
  color: #17BEBB;
  margin: 1.2rem 0;

  @include fridge-medium {
    margin: 1.2rem 0 4rem;
  }
}

.secondaryText {
  font-size: 0.875rem;
  color: #989898;
  margin-bottom: 2rem;
}

.message {
  font-size: 0.875rem;
  color: #989898;
}

@media screen and (min-height: 1000px) {

  .text {
    font-size: 2.5rem;
  }

  .message {
    font-size: 1.75rem;
  }
}


