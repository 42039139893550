@import "../../variables";

.container {
  padding: 1rem 1.875rem;
}

.mediaContainer {
  height: 300px;

  video {
    min-width: 100%;
    width: auto !important;
    height: auto !important;
  }

  @include fridge-large {
    height: 462px;
  }
}

.imgContainer {
  display: block;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.carouselImg {
  height: 100%;
  width: auto;
}
