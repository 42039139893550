.input-container {
  margin-top: 1rem;
}

.input {
  background: transparent;
  border: none;
  box-sizing: border-box;
  border-radius: 3px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 0.8125rem;
  color: #0c0f0a;
  width: 100%;
  vertical-align: middle;
  border-bottom: 2px solid #c4c4c4;
}

.focused .input {
  height: 30px;
  line-height: 22px;
  padding: 20px 0px;
  outline: none;
}

.focused .required-label {
  font-size: 0.75rem;
}

.input::placeholder {
  color: #c4c4c4;
}

.input-error {
  color: #f05373;
  border-color: #f05373;
}

.input-error::placeholder {
  color: #f05373;
}

.error {
  width: 100%;
  color: #f05373;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 0.75rem;
  text-align: center;
  padding: 0;
  margin: 5px 0;
}

.error > li {
  list-style-type: none;
}

.button {
  position: relative;
  background: linear-gradient(102.69deg, #f25454 1.35%, #ff9345 103.4%);
  border: none;
  border-radius: 20px;
  outline: 0;
  height: 40px;
  width: 100%;
  margin-top: 15px;
  font-family: Montserrat, sans-serif;
  font-weight: bold;
  font-size: 0.625rem;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
}

.button[disabled] {
  background: linear-gradient(102.69deg, #f08484 1.35%, #ff9345 103.4%);
}

.chevron {
  position: absolute;
  right: 10px;
  top: 10px;
}

.labels-container {
  width: 100%;
}

.required-label {
  color: #f05373;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  float: right;
}

.label {
  vertical-align: middle;
  text-align: left;
  float: left;
  margin-bottom: 0;
  color: #c4c4c4;
  font-family: Montserrat;
  font-size: 0.75rem;
}

.label a {
  color: inherit;
  text-decoration: underline;
  font-weight: bold;
}

.label-error {
  color: #f05373;
}

.autocomplete_menu {
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.4);
  border: 1px solid #0c0f0a;
  border-top: none;
  max-height: 151px;
  overflow: auto;
}

.autocomplete_item {
  font-size: 0.8125rem;
  font-weight: 600;
  line-height: 30px;
  height: 30px;
  overflow: hidden;
  text-align: left;
  padding: 0 10px;
  color: #0c0f0a;
  cursor: pointer;
}

.autocomplete_item-highlighted {
  background-color: #ff9345;
  color: #ffffff;
}
