.page {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.page-content {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
}

.page-content > * {
  width: 100%;
}

.center {
  justify-content: center;
}
