@import "../../variables";

.productPopup {

}

.popupCloseIcon {
  position: absolute;
  right: 7px;
  top: 7px;
  fill: #fff;
  width: 36px;
  height: 36px;

  @include fridge-medium {
    display: none;
  }
}

.productInfos {
  display: inline-flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid #fff;
  border-radius: 8px;
}

.productHeader {
  margin-top: 1rem;
  margin-bottom: 1rem;

  h3 {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  span {
    padding: 0.5rem 0.5rem;
    border-right: 1px solid #fff;

    &:last-of-type {
      border: 0;
      font-weight: 700;
    }

    @include fridge-medium {
      font-size: 20px;
      padding: 1rem 2rem;
    }
  }

  @include fridge-medium {
    margin-bottom: 2rem;
    margin-top: 0;
  }
}

.productName {
  font-size: 2rem;
}

.productPrice {
  font-size: 1.25rem;
  color: #909090;
  span {
    margin-left: 1rem;
  }
}

.productBody {
  font-size: 14px;
  padding: 1rem;
  @include fridge-medium {
    padding: 2rem;
  }
  @include fridge-large {
    font-size: 20px;
  }
}

.promotionTags {
  border-radius: 16px;
  border: 1px solid #C4C4C4;
  font-family: monospace;
  text-align: center;
  flex-wrap: wrap;
  color: #989898;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  max-height: 240px;
  overflow-y: auto;
  padding: 20px 0;

  span {
    min-width: 50%;
    padding: 0 20px;
  }
}

.productBodyHeader {
  display: flex;
  align-items: center;
  justify-content: center;
}

.productPicture {
  text-align: center;

  img {
    width: 100%;
    max-width: 180px;

    @include fridge-medium {
      max-width: 300px;
    }
  }

  @include fridge-medium {
    padding: 5px;
  }
}

.productNutriScore {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  svg {
    width: 106px;
    height: auto;
  }
}

.productDescription {
  max-height: 300px;
  overflow-y: auto;

  font-size: 14px;

  p {
    margin-bottom: 15px !important;
  }

  @include fridge-medium {
    max-height: 300px;
    font-size: 1rem;
  }

  @include fridge-large {
    max-height: 600px;
    font-size: 20px;
  }
}

.productPriceContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: 50px;
}

.promotion {
  display: inline-block;
  border: 1px solid #F05373;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  padding: 5px 10px;
  line-height: 1em;
  color: white;
  background-color: #F05373;
  border-radius: 35px;
}

.productPrice {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  text-align: left;
  font-size: 60px;
  font-weight: 700;
  color: $primary-color;
}

.productPriceInitial {
  text-decoration: line-through;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  color: #989898;
  margin-left: 1em;
}
