.paygreenWrapper {

  :global(.paygreen-pan-frame) {
      padding: 0 0.5rem;
  }

  :global(.paygreen-cvv-frame) {
    padding: 0 0.5rem;
  }

  :global(.paygreen-exp-frame) {
    padding: 0 0.5rem;
  }
}

.paygreenForm {
  width: 80%;
  margin: auto;
}
.paygreenRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px 0 ;
}
.wFull {
  width: 100%;
}
.paygreenCol {
  width: 45%;
}
