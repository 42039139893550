.infos {
    font-family: Montserrat, sans-serif;
    display: flex;
    flex-direction: row;
    padding: 1rem 1.875rem;
    align-items: center;

    > div:first-child {
        text-align: left;
        color: #f05373;
        font-size: 0.875rem;
        flex: 2 1 0;
    }

    > div {
        flex: 1 1 0;

        > img {
            width: 100%;
        }
    }
}

.paymentType {
    display: flex;
    flex-direction: column;
    line-height: 1.2rem;

    svg {
        margin-right: 10px;
        height: 23px;
        width: auto;

        &:last-of-type {
            margin-right: 0;
        }
    }
}

.secondaryPaymentMethodsHint {
  font-size: 0.8125rem;
  color: #2a2a72;
  margin-top: 1.5rem;
}

.secondaryPaymentMethods {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}

.secondaryPaymentMethods svg {
  margin-right: 10px;
  height: 18px;
  width: auto;
}

.secondaryPaymentMethods svg:last-child {
  margin-right: 0;
}

