.form {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.paper {
  height: auto;
  flex: 1;
}

.fridgeList {
  margin-top: 2rem;
  flex: 0;
}
