.button,
.button:visited,
.button:hover,
.button:active {
  padding-left: 3rem;
}

.flag {
  height: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
