@import "../../../variables";

.list {
  padding: 0.75rem 1.875rem;
  width: 100%;
}

.inner {
  font-family: Montserrat, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  position: relative;
  background-color: #ffffff;
  border-radius: 9px;
}

.innerResponsive {
  @include fridge-large {
    display: flex;
    flex-wrap: wrap;
    background-color: transparent;
    margin-right: -1rem;
    margin-left: -1rem;
  }
}

.title {
  font-family: Montserrat, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 0.8125rem;
  color: #0c0f0a;
  text-align: left;
  margin-bottom: 8px;
  width: 100%;
}

.cell {
  border-top: 1px solid #c4c4c4;
  padding: 1rem 1rem;
  text-align: left;
  color: #0c0f0a;
  font-family: Montserrat, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.375rem;

  &:first-child {
    border-top: none;
  }
}

.cellResponsive {
  @include fridge-large {
    border: none;
    position: relative;
    box-shadow: 0px 2px 28px 0px #0000000F;
    background-color: #fff;
    border-radius: 9px;
    margin: 1rem;
    width: calc(50% - 2rem);
    padding: 20px;

    &:first-child {
      border: none;
    }
  }
}

.title + .cell {
  border-top: none;
}
