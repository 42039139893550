.products {
  display: table;
  width: 100%;
  text-align: left;
  overflow: auto;
}

.product {
  display: table-row;
  font-size: 0.875rem;
  line-height: 1.75rem;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
}

.product-quantity,
.product-name,
.product-price {
  display: table-cell;
}

.productPriceReduction {
  color: #989898;
  font-weight: normal;
}

.product-quantity {
  padding-right: 1.5rem;
}

.product-name {
  width: 100%;
}

.product-price {
  text-align: right;
  color: #2a2a72;
}

.tax {
  color: #989898;
}

.text-align-right {
  text-align: right;
}

.total {
  color: #2a2a72;
}

.paymentInfo {
  font-size: 1rem;
}
.paymentInfo strong:first-of-type {
  color: #2a2a72;
}

@media screen and (min-height: 1000px) {
  .product {
    font-size: 1.25rem;
    line-height: 2.5rem;
  }
}
