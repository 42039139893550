.big-text {
  width: 100%;
  height: 100%;
}

.text-aligner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 0 30px;
  color: #0c0f0a;
  font-family: Montserrat, sans-serif;
  font-size: 2rem;
  font-weight: 600;
}
