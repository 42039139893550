.footer {
  font-family: Montserrat, sans-serif;
  position: relative;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text {
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  color: #0c0f0a;
}

.text a, .text span {
  text-decoration: none;
  font-weight: bold;
  color: #2a2a72;
}

.version {
  text-decoration: none;
  font-weight: normal;
  color: #ccc;
  font-size: 0.875rem;
  margin-left: 5px;
}
