.container {
  width: 100%;
}

.block {
  width: 100%;
  margin-bottom: 1rem;
}

.arrows {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1.25rem;
  fill: #2a2a72;
}

.arrow,
.arrow:visited,
.arrow:hover,
.arrow:active {
  display: block;
  width: 1.5rem;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  background: none;
  fill: #2a2a72;
}

.arrow > svg {
  display: block;
  width: 100%;
  height: auto;
}

.head {
  font-size: 0.8125rem;
}

.loading {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1.5rem;
  fill: #2a2a72;
  height: 1.5rem;
  width: 1.5rem;
}

.button {
  width: 100%;
  padding: 1rem 1.875rem;
}

.secondaryPaymentMethodsHint {
  font-size: 0.8125rem;
  color: #2a2a72;
  margin-top: 0.5rem;
}

.secondaryPaymentMethods {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}

.secondaryPaymentMethods svg {
  margin-right: 10px;
  height: 18px;
  width: auto;
}

.secondaryPaymentMethods svg:last-child {
  margin-right: 0;
}
