@import "../../../variables";

.container {
  background-color: $primary-color;
  padding: 10px;
  color: #fff;
  box-shadow: 0 2px 28px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  font-size: 20px;
  line-height: 26px;
  font-weight: 700;
  text-align: center;
  display: flex;
  align-items: center;
  position: relative;
  min-width: 275px;

  @include fridge-large {
    min-width: 385px;
  }
}

.info {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 24px;
  @include fridge-large {
    top: 13px;
    right: 14px;
  }
}

.body {
  background-color: #fff;
  border-radius: 2px;
  padding: 5px;
  width: 90px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;

  @include fridge-large {
    width: 120px;
    height: 120px;
  }
}

.icon {
  width: 80px;
  color: $primary-color;
  background-color: white;

  @include fridge-large {
  }
}

.label {
  text-align: center;
  flex: 1;

  span {
    display: block;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 1.5rem;
    @include fridge-large {
      font-size: 40px;
      line-height: 52px;
    }
    &:last-of-type {
      text-transform: none;
      font-size: 1rem;
      @include fridge-large {
        font-size: 20px;
        line-height: 26px;
      }
    }
  }
}
